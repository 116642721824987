import DarkExample from "../Functions/table"

export default function TablaDeTipos() {
    return (
        <div className="App">
        <header className="App-TypePokemon">
            <DarkExample></DarkExample>
        </header>
        </div>
    )
}